import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoleFacade } from '@adminpanels/shared-ui';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { distinctUntilChanged, filter, Subject } from 'rxjs';
import BusinessFacade from './modules/business/business.facade';
import { AuthService } from '@auth0/auth0-angular';
import BusinessService from './modules/business/business.service';
import { fullSessionTracker } from 'fullsession';
import { BusinessUserResponse } from '@adminpanels/api-clients/orderadmin-api-client';
import { environment } from '../environments/environment';

@Component({
  selector: 'orderadmin-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  private readonly _destroy$ = new Subject();

  constructor(
    private readonly _translate: TranslateService,
    private readonly _roleFacade: RoleFacade,
    private readonly _businessFacade: BusinessFacade,
    private readonly _businessService: BusinessService,
    private readonly _auth0: AuthService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    fullSessionTracker.initialize('cus_m3irwwbhjik');
    this._auth0.idTokenClaims$
      .pipe(
        filter((token) => !!token),
        distinctUntilChanged(),
      )
      .subscribe((token) => {
        this.handleTokenClaims(token);
      });
  }

  private handleTokenClaims(token: any): void {
    if (token) {
      const roles =
        token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const lowercaseRoles = roles.map((r: string) => r.toLowerCase());

      this._roleFacade.setRoles(lowercaseRoles);
      this.initializeWithData(token['sub']);
    }
  }

  private initializeWithData(businessUserId: string): void {
    this._businessFacade.refreshStore();
    // this._translate.addLangs(['en', 'de']);
    // this._translate.setDefaultLang('en');

    this.handleRouteQueryParams(businessUserId);
  }

  private handleRouteQueryParams(businessUserId: string): void {
    const snapshot: ActivatedRouteSnapshot = this._route.snapshot;
    const paramMap = snapshot.queryParams;
    this.processQueryParams(paramMap, businessUserId);
  }

  private processQueryParams(paramMap: any, businessUserId: string): void {
    const redirect = paramMap['redirect'];
    const businessId = paramMap['businessId'];
    const userId = paramMap['userId'];

    const businessIdStore = this._businessFacade.getCurrentBusinessId();
    const userIdStore =
      this._businessFacade.getCurrentUserId() || businessUserId;

    if (redirect) this._router.navigate([redirect]);
    else {
      if (businessId && userId) {
        this.setBusinessAndUser(businessId, userId);
      } else if (!businessIdStore) {
        this._businessService
          .getBusinessId(businessUserId)
          .subscribe((res: BusinessUserResponse) => {
            this.setBusinessAndUser(res.businessId || 0, businessUserId);
          });
      } else {
        this.setBusinessAndUser(businessIdStore, userIdStore);
      }
    }
  }

  private setBusinessAndUser(businessId: number, userId: string): void {
    this._businessFacade.setBusinessId(businessId);
    this._businessFacade.setUserId(userId);
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
